// Import scripts from components
import '../templates/components/Atoms/hamburger';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    // Your code that requires the DOM to be ready.
  },
  false
);

window.addEventListener('load', () => {
  // Your code that requires all assets to be loaded, e.g. images.
});
