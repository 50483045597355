document.querySelectorAll('.hamburger').forEach((hamburger) =>
  hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('is-active');
    hamburger.setAttribute(
      'aria-expanded',
      hamburger.classList.contains('is-active').toString()
    );
    // Don't open/close menu here, do it where the menu is defined.
  })
);
